import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title }) {
    const site = useStaticQuery(
        graphql`
            query {
                allWordpressSiteMetadata {
                    nodes {
                        name
                        description
                    }
                }
            }
        `
    );

    const metaDescription = site.allWordpressSiteMetadata.nodes[0].description;

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            encodeSpecialCharacters
            title={title}
            titleTemplate={`%s | ${metaDescription}`}
            meta={[
                {
                    name: `description`,
                    content: description,
                },
                {
                    name: `robots`,
                    content: 'index, follow',
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
            ].concat(meta)}
        />
    );
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
};

export default SEO;
